import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';





const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});


class SearchField extends React.Component {

    constructor(props) {
        super(props);
        this.state = { partnumber: '' };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ partnumber: event.target.value });
    }



    render() {
        const { classes } = this.props;

        return (
            <form className={classes.container} noValidate autoComplete="off" onSubmit={e => { e.preventDefault(); }}>
                <TextField
                    id="filled-search"
                    label="Part number"
                    type="search"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    value={this.state.value}
                    onChange={this.handleChange}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton className={classes.button} aria-label="Delete"
                                onClick={() => this.props.executeSearch(this.state.partnumber)}
                            >
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>,
                    }}
                />
            </form>
        );
    }
}

SearchField.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchField);
